export const identifications = [
    { value: 0, text: "cedula" },
    { value: 1, text: "passport" }
]

export const eventTypes = [
    // Superintendence
    { value: 200, key: "read-superintendence-info", userTypes: [] },
    { value: 201, key: "register-superintendence", userTypes: [1] },
    { value: 202, key: "send-superintendence-invitation", userTypes: [1] },
    { value: 203, key: "disabled-superintendence", userTypes: [1] },

    // Resident Agent
    { value: 300, key: "read-resident-agent-info", userTypes: [] },
    { value: 301, key: "register-resident-agent", userTypes: [0] },
    { value: 302, key: "send-resident-agent-invitation", userTypes: [0,1, 4] },
    { value: 303, key: "disabled-resident-agent", userTypes: [0,1] },
    { value: 304, key: "update-resident-agent", userTypes: [] },
    { value: 308, key: "send-natural-person-invitation", userTypes: [1] },
    { value: 309, key: "disable-natural-person", userTypes: [1] },
    { value: 310, key: "disable-legal-person", userTypes: [0, 1, 4] },
    { value: 311, key: "send-legal-person-invitation", userTypes: [ 1, 4] },


    // Official
    { value: 400, key: "read-official-info", userTypes: [2]},
    { value: 401, key: "register-official", userTypes: [2] },
    { value: 402, key: "send-official-invitation", userTypes: [1,2] },
    { value: 403, key: "disabled-official", userTypes: [2] },
    { value: 404, key: "update-official-role", userTypes: [] },

    // LegalEntity
    { value: 500, key: "generate-legal-entity-certificate", userTypes: [0, 4] },
    { value: 501, key: "read-legal-entity", userTypes: [] },
    { value: 502, key: "create-legal-entity", userTypes: [0,  4] },
    { value: 506, key: "create-natural-entity", userTypes: [0, 3] },
    { value: 503, key: "disable-legal-entity", userTypes: [0, 4] },
    { value: 504, key: "edit-legal-entity", userTypes: [0, 3, 4] },
    { value: 505, key: "generate-legal-entity-certificate-of-separation", userTypes: [0, 4] },

    // Final Beneficiary
    { value: 600, key: "read-final-beneficiary", userTypes: [] },
    { value: 601, key: "read-final-beneficiaries", userTypes: [] },
    { value: 602, key: "delete-final-beneficiary", userTypes: [0,3,4] },
    { value: 603, key: "create-final-beneficiary", userTypes: [0, 3, 4] },
    { value: 604, key: "update-final-beneficiary", userTypes: [0, 3, 4] },

    // Law Firm
    { value: 700, key: "read-law-firm", userTypes: [0, 4] },
    { value: 701, key: "create-law-firm", userTypes: [1] },
    { value: 703, key: "disabled-law-firm", userTypes: [1] },

    // Competent Authority
    { value: 800, key: "get-competent-authority", userTypes: [] },
    { value: 801, key: "create-competent-authority", userTypes: [1] },
    { value: 803, key: "disabled-competent-authority", userTypes: [1, 2] },
    { value: 802, key: "approved-competent-authority", userTypes: [1] },

    // Nominee
    //{ value: 900, key: "read-nominee" },
    //{ value: 901, key: "create-director" },
    //{ value: 902, key: "delete-director" },
    //{ value: 903, key: "create-shareholder" },
    //{ value: 904, key: "delete-shareholder" },

    // Legal Representative
    // { value: 1000, key: "read-legal-representative", userTypes: [] },
    { value: 1001, key: "read-legal-representatives", userTypes: [] },
    // { value: 1002, key: "delete-legal-representative", userTypes: [2] },
    // { value: 1003, key: "create-legal-representative", userTypes: [0, 3, 4] },

    // Criteria
    { value: 1100, key: "read-criteria", userTypes: [] },
    { value: 1101, key: "read-criterias", userTypes: [] },
    { value: 1102, key: "update-criteria", userTypes: [1] },
    { value: 1103, key: "create-criteria", userTypes: [1] },

    // Activation/Inactivation
    { value: 1200, key: "create-request-activation-inactivation", userTypes: [1] },
    { value: 1201, key: "get-request-activation-inactivation", userTypes: [] },
    { value: 1202, key: "list-request-activation-inactivation", userTypes: [] },

    // Account
    { value: 2000, key: "event-login", userTypes: [0, 1, 3, 4, 2] },
    { value: 2001, key: "event-logout", userTypes: [0, 1, 3, 4, 2] },
    { value: 2002, key: "event-request", userTypes: [0, 1, 3, 4, 2] },
    { value: 2003, key: "event-file-upload", userTypes: [0, 1, 3, 4, 2] },
    { value: 2004, key: "event-file-download", userTypes: [0, 1, 3, 4, 2] },
    { value: 2005, key: "event-modified", userTypes: [0, 1, 3, 4, 2] },
    { value: 2006, key: "event-moduleview", userTypes: [0, 1, 3, 4, 2] },
    { value: 2007, key: "event-removed", userTypes: [0, 1, 3, 4, 2] },
    { value: 2008, key: "event-invitation", userTypes: [0, 1, 4, 2] }
]
