<template>
    <div class="mt-5 px-xl-5">
        <h6 class="page-title pb-2">
            REGISTRO NUEVO USUARIO<b-spinner
                v-if="loading"
                class="ms-2"
                variant="primary"
                small
            ></b-spinner>
        </h6>
        <b-row class="mb-5">
            <b-card
                class="shadow-sm border-0 rounded-0 px-md-4"
                bg-variant="light"
                no-header
            >
                <b-card-text>
                    <b-row class="mt-2">
                        <b-col md="6" class="pe-md-5 pb-5">
                            <b-form-group>
                                <label class="form-title" label-for="name-input"
                                    >Nombre completo</label
                                >
                                <b-form-input
                                    id="name-input"
                                    v-model="registryForm.name"
                                    size="sm"
                                    class="rounded-0"
                                    disabled
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group class="mt-3">
                                <label class="form-title" label-for="name-input"
                                    >Nacionalidad</label
                                >
                                <select
                                    class="form-select form-select-sm rounded-0"
                                    v-model="registryForm.nationality"
                                >
                                    <option
                                        v-for="country in countries"
                                        :key="country"
                                        :value="country"
                                    >
                                        {{ country }}
                                    </option>
                                </select>
                            </b-form-group>
                            <b-form-group class="mt-3">
                                <label class="form-title">{{
                                    $t("general.identification-type")
                                }}</label>
                                <select
                                    class="form-select form-select-sm rounded-0"
                                    v-model="registryForm.identificationType"
                                >
                                    <option
                                        v-for="option in identifications"
                                        :key="option.value"
                                        :value="option.value"
                                    >
                                        {{ $t(`general.${option.text}`) }}
                                    </option>
                                </select>
                            </b-form-group>
                            <b-form-group class="mt-3">
                                <label class="form-title" label-for="name-input"
                                    >Identificación</label
                                >
                                <b-form-input
                                    id="name-input"
                                    v-model="registryForm.identification"
                                    size="sm"
                                    class="rounded-0"
                                    disabled
                                ></b-form-input>
                            </b-form-group>
                            <b-row class="mt-4">
                                <b-col md="9" lg="6" xl="4">
                                    <label class="form-title"
                                        >Foto de la cédula/pasaporte</label
                                    >
                                    <div class="file-input mt-1">
                                        <label
                                            for="image"
                                            class="btn main-btn btn-secondary"
                                            ><b-icon-file-earmark-arrow-up
                                                class="me-1"
                                            ></b-icon-file-earmark-arrow-up
                                            >Seleccionar archivo</label
                                        >
                                        <input
                                            id="image"
                                            type="file"
                                            accept=".jpg,.jpeg,.png"
                                            @change="onInputChange"
                                        />
                                    </div>
                                </b-col>
                                <b-col class="file-name-container">
                                    <span
                                        v-if="registryForm.cedulaFileName"
                                        class="file-name"
                                        >{{ registryForm.cedulaFileName }}</span
                                    >
                                    <span v-else class="file-name"
                                        >Ning&uacute;n archivo
                                        seleccionado</span
                                    >
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col md="6" class="ps-md-5">
                            <b-form-group>
                                <label class="form-title" label-for="name-input"
                                    >Fecha de Nacimiento</label
                                >
                                <DatePicker v-model="registryForm.birthDate" />
                            </b-form-group>
                            <b-form-group class="mt-3">
                                <label class="form-title" label-for="name-input"
                                    >Tel&eacute;fono</label
                                >
                                <b-form-input
                                    id="name-input"
                                    v-model="registryForm.phone"
                                    size="sm"
                                    class="rounded-0"
                                    disabled
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group class="mt-3">
                                <label class="form-title" label-for="name-input"
                                    >Correo electrónico</label
                                >
                                <b-form-input
                                    id="name-input"
                                    v-model="registryForm.email"
                                    size="sm"
                                    class="rounded-0"
                                    disabled
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group class="mt-3">
                                <label class="form-title" label-for="name-input"
                                    >Direcci&oacute;n</label
                                >
                                <b-form-input
                                    id="name-input"
                                    v-model="registryForm.direction"
                                    size="sm"
                                    class="rounded-0"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                class="mt-3"
                                v-if="isLawyerInvitation"
                            >
                                <label class="form-title" label-for="name-input"
                                    >Idoneidad</label
                                >
                                <b-form-input
                                    id="name-input"
                                    v-model="registryForm.eligibilityNumber"
                                    size="sm"
                                    class="rounded-0"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col class="text-end">
                            <b-overlay
                                :show="sending"
                                rounded
                                opacity="0.7"
                                spinner-small
                                spinner-variant="primary"
                                class="d-inline-block"
                            >
                                <b-button
                                    class="main-btn rounded px-4"
                                    @click="registerResidentAgent"
                                    :disabled="!canSave"
                                    >Guardar</b-button
                                >
                            </b-overlay>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-row>
    </div>
</template>

<script>
import moment from "moment";

import { RepositoryFactory } from "@/services/api";
import { isUserRegisteredAndEnabled } from "@/services/users";
import { mapActions, mapGetters } from "vuex";
import { countries } from "@/config/countries";
import { identifications } from "@/config/enums";

import DatePicker from "@/components/widgets/DatePicker.vue";

export default {
    name: "register-resident-agent",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            countries: countries,
            identifications: identifications,
            invitation: {},
            lawFirm: null,
            sending: false,
            loading: false,
            registryForm: {
                name: "",
                nationality: "",
                identificationType: null,
                identification: "",
                birthDate: "",
                phone: "",
                email: "",
                direction: "",
                eligibilityNumber: "",
                cedulaFileName: "",
                cedulaPhotoContent: null,
            },
        };
    },
    async mounted() {
        this.loading = true;

        let userRegistered = await isUserRegisteredAndEnabled();
        if (userRegistered) {
            this.setModalInfo({
                title: this.$t("user-registered.title"),
                message: this.$t("user-registered.message"),
                subMessage: this.$t("user-registered.subMessage"),
                routeName: "dashboard",
            });
            this.openModal();
            this.loading = false;
            return;
        }

        // Check invitation is not used
        let valid = await this.isValidInvitation();
        if (!valid) {
            return;
        }

        let user = this.oidcUser;

        if (this.lawFirm.lawFirmType === 0) {
            // Legal
            valid = this.isValidLegalPerson(user);
        } else if (this.lawFirm.lawFirmType === 1) {
            // Natural
            valid = this.isValidNaturalPerson(user);
        }

        if (!valid) {
            return;
        }

        this.registryForm.name =
            `${user["given_name"]} ${user["family_name"]}`.trim();
        this.registryForm.phone = user["phone_number"];
        this.registryForm.identification = user["cedula"] ?? user["passport"];

        var birthDate = moment(user["birthdate"].split(" ")[0], "MM/DD/yyyy");
        if (!birthDate.isValid()) {
            birthDate = moment(user["birthdate"], "yyyy-MM-DD");
        }
        this.registryForm.birthDate = birthDate.format("yyyy-MM-DD");

        this.loading = false;
    },
    computed: {
        ...mapGetters("oidcStore", ["oidcUser"]),
        isLawyerInvitation() {
            return this.invitation.residentAgentType === 0;
        },
        canSave() {
            if (this.loading) {
                return false;
            }
            let form = this.registryForm;
            let validFields =
                form.nationality !== "" &&
                form.identificationType !== null &&
                form.identification !== "" &&
                form.direction !== "" &&
                form.cedulaFileName !== "";
            if (this.isLawyerInvitation) {
                return validFields && form.eligibilityNumber !== "";
            }
            return validFields;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        isValidLegalPerson(user) {
            let profileSelected = user["profile_selected"];
            if (this.isLawyerInvitation) {
                if (profileSelected !== "lawyersignature") {
                    this.showNotValidProfile("not-valid-lawyer-profile");
                    return false;
                }

                let validEmail = user["profile_lawyersignature_verified"];
                if (validEmail !== "True") {
                    this.showNotValidProfile("not-validated-lawyer-email");
                    return false;
                }

                let validatedBusiness =
                    user["profile_lawyersignature_verifiedadministrator"];
                if (validatedBusiness !== "True") {
                    this.showNotValidProfile("not-validated-by-business");
                    return false;
                }

                let email = user["profile_lawyersignature_email"];
                if (email !== this.invitation.email) {
                    this.setModalInfo({
                        title: "Usuario no valido",
                        message:
                            "El usuario con el que esta intentando usar esta invitación no es valido",
                        subMessage:
                            "Por favor, contacte con quien le envió esta invitación y solicite una nueva",
                        routeName: "panama-digital",
                    });
                    this.openModal();
                    return false;
                }

                let collegiateNumber = user["profile_lawyer_collegiatenumber"];
                if (collegiateNumber !== undefined) {
                    this.registryForm.eligibilityNumber = collegiateNumber;
                }

                this.registryForm.email = email;
            } else {
                if (profileSelected !== "employeebelonging") {
                    this.showNotValidProfile("not-valid-employee-profile");
                    return false;
                }

                let validEmail = user["profile_employeebelonging_verified"];
                if (validEmail !== "True") {
                    this.showNotValidProfile("not-validated-employee-email");
                    return false;
                }

                let validatedBusiness =
                    user[
                        "profile_employeebelonging_verifiedlegalrepresentative"
                    ];
                if (validatedBusiness !== "True") {
                    this.showNotValidProfile("not-validated-by-business");
                    return false;
                }

                let email = user["profile_employeebelonging_email"];
                if (email !== this.invitation.email) {
                    this.setModalInfo({
                        title: "Usuario no valido",
                        message:
                            "El usuario con el que esta intentando usar esta invitación no es valido",
                        subMessage:
                            "Por favor, contacte con quien le envió esta invitación y solicite una nueva",
                        routeName: "panama-digital",
                    });
                    this.openModal();
                    return false;
                }

                this.registryForm.email = email;
            }

            return true;
        },
        isValidNaturalPerson(user) {
            let profileSelected = user["profile_selected"];
            if (profileSelected !== "lawyer") {
                this.showNotValidProfile("not-valid-natural-lawyer-profile");
                return false;
            }

            // TODO CHECK EMAIL VALIDATED
            // let validatedEmail = user["profile_lawyer_email_validated"];
            // if (validatedEmail !== "True") {
            //     this.showNotValidProfile("not-validated-email");
            //     return false;
            // }

            let email = user["profile_lawyer_email"];
            if (email !== this.invitation.email) {
                this.setModalInfo({
                    title: "Usuario no valido",
                    message:
                        "El usuario con el que esta intentando usar esta invitación no es valido",
                    subMessage:
                        "Por favor, contacte con quien le envió esta invitación y solicite una nueva",
                    routeName: "panama-digital",
                });
                this.openModal();
                return false;
            }

            let collegiateNumber = user["profile_lawyer_collegiatenumber"];
            if (collegiateNumber !== undefined) {
                this.registryForm.eligibilityNumber = collegiateNumber;
            }

            this.registryForm.email = email;
            return true;
        },
        showNotValidProfile(key) {
            this.setModalInfo({
                title: this.$t(`${key}.title`),
                message: this.$t(`${key}.message`),
                subMessage: this.$t(`${key}.subMessage`),
                routeName: "panama-digital",
            });
            this.openModal();
        },
        async isValidInvitation() {
            let response = await RepositoryFactory.residentAgent.getInvitation({
                id: this.id,
            });
            this.invitation = response.data;
            this.lawFirm = this.invitation.lawFirm;

            if (this.invitation.used) {
                this.setModalInfo({
                    title: "Invitación no valida",
                    message:
                        "La invitación ya ha sido utilizada por otro usuariuo.",
                    subMessage:
                        "Por favor, contacte con quien le envió esta invitación y solicite una nueva.",
                    routeName: "panama-digital",
                });
                this.openModal();
                return false;
            }

            return true;
        },
        async registerResidentAgent() {
            this.sending = true;
            try {
                let residentAgent = { ...this.registryForm };
                residentAgent.residentAgentType =
                    this.invitation.residentAgentType;
                let payload = {
                    invitationId: this.id,
                    residentAgent: residentAgent,
                };
                await RepositoryFactory.residentAgent.add(payload);
                this.setSuccesInfo();
            } catch (error) {
                window.console.log(error);
                this.setErrorInfo();
            }
            this.openModal();
            this.sending = false;
        },
        setSuccesInfo() {
            let cur = this.lawFirm.cur;
            this.setModalInfo({
                title: "Usuario registrado correctamente",
                message: `Se ha registrado correctamente en la aplicación. El CUR que se le ha asigado es ${cur}`,
                subMessage:
                    "IMPORTANTE! Se le va a cerrar la sesión para actualizar los datos de su usuario, por favor vuelva a iniciar sesión para acceder a la aplicación.",
                routeName: "logout",
            });
        },
        setErrorInfo() {
            this.setModalInfo({
                title: "Error registrando usuario",
                message: "Se ha producido un error al intentar registrarse.",
                subMessage: "Por favor revise los datos y vuelva a intentarlo.",
            });
        },
        onInputChange(e) {
            let image = e.target.files[0];

            let extension = image.name.split(".").pop();
            if (["jpg", "jpeg", "png"].indexOf(extension) < 0) {
                return;
            }

            this.registryForm.cedulaFileName = image.name;
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = (e) => {
                let content = e.target.result;
                this.registryForm.cedulaPhotoContent = content
                    .split(";base64,")
                    .pop();
            };
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .file-name-container {
        margin-top: 1.5rem !important;
    }
}

@media (max-width: 765px) {
    .file-name-container {
        margin-top: 1rem;
    }
}
</style>